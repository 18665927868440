import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/ruoyi'






// 订阅趋势
export function vipData (query) {
  return request({
    url: '/biz/users/vipData',
    method: 'get',
    params: query
  })
}

// 上方数据
export function getTopData (query) {
  return request({
    url: '/biz/users/getTopData',
    method: 'get',
    params: query
  })
}

// 订阅地域排行
export function gerVipAddressData (query) {
  return request({
    url: '/biz/users/gerVipAddressData',
    method: 'get',
    params: query
  })
}
export function gerUserAddressData (query) {
  return request({
    url: '/biz/users/gerUserAddressData',
    method: 'get',
    params: query
  })
}

//充值地域排行
export function gerPayAddressData (query) {
  return request({
    url: '/biz/users/gerPayAddressData',
    method: 'get',
    params: query
  })
}


//充值方式累计金额统计
export function gerPayMoneyData (query) {
  return request({
    url: '/biz/users/gerPayMoneyData',
    method: 'get',
    params: query
  })
}

//性别统计
export function getUserSex (query) {
  return request({
    url: '/biz/users/getUserSex',
    method: 'get',
    params: query
  })
}


//充值方式统计
export function payTypeData (query) {
  return request({
    url: '/biz/users/payTypeData',
    method: 'get',
    params: query
  })
}



//充值趋势
export function rechargeData (query) {
  return request({
    url: '/biz/users/rechargeData',
    method: 'get',
    params: query
  })
}